/**
 * C端积分资产
 * */
export default [
    {
        path: '/financial/integrateUser/integrateInfo',
        component: resolve => require(['../../components/pages/financial/integrateUser/integrateInfo.vue'], resolve),
        meta: {title: '积分资产概览'}
    },
    {
        path: '/financial/integrateUser/integrateWater',
        component: resolve => require(['../../components/pages/financial/integrateUser/integrateWater.vue'], resolve),
        meta: {title: '商户积分流水'}
    },
    {
        path: '/financial/integrateUser/integrateWaterUser',
        component: resolve => require(['../../components/pages/financial/integrateUser/integrateWaterUser.vue'], resolve),
        meta: {title: '用户积分流水'}
    },
    {
        path: '/financial/integrateUser/integrateWaterUserUse',
        component: resolve => require(['../../components/pages/financial/integrateUser/integrateWaterUserUse.vue'], resolve),
        meta: {title: '用户消费流水'}
    },
    {
        path: '/financial/integrateUser/integrateWaterUserOut',
        component: resolve => require(['../../components/pages/financial/integrateUser/integrateWaterUserOut.vue'], resolve),
        meta: {title: '用户进出汇总'}
    },
    {
        path: '/financial/integrateUser/integrateAllOut',
        component: resolve => require(['../../components/pages/financial/integrateUser/integrateAllOut.vue'], resolve),
        meta: {title: '积分发放汇总'}
    },
    {
        path: '/financial/integrateUser/integrateAllUse',
        component: resolve => require(['../../components/pages/financial/integrateUser/integrateAllUse.vue'], resolve),
        meta: {title: '积分消费汇总'}
    },
    {
        path: '/financial/integrateUser/integrateOperateLog',
        component: resolve => require(['../../components/pages/financial/integrateUser/integrateOperateLog.vue'], resolve),
        meta: {title: '积分明细'}
    },
]